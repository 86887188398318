/*-------------------- MTC ----------------------*/

/* At the top level of your code */

html {
  font: 14px "Century Gothic", Futura, sans-serif;
}

/* iPhone portrait */
@media screen and (width <= 590px) {
  body {
    margin: 0;
    background: #000;
  }

  .leader-board-container {
    background-size: contain;
    background-position: 50% 5%; /* Center the image */
  }

  .leader-board-h3 {
    margin-top: 1.3em;
  }

  .btn-learn-more {
    width: 150px;
    margin-top: 9px;
    margin-left: auto;
    margin-right: 10px;
  }

  .header-nav-game {
    display: none;
  }

  .header-nav-motto {
    display: none;
  }

  .practice-footer-correct-answer-txt {
    line-height: 2.2em;
    height: 50px;
    margin-left: 56%;
  }

  /*.practice-round-footer-instructions {
     line-height: 2.2em;
    height: 50px;
    margin-left: 56%;
  } */

  .board-instructions-color {
    top: 45%;
    left: 13%;
    text-align: left;
    background-image: url("/inst-arrow-colors.png");
    background-repeat: no-repeat, repeat;
    background-position: left 2.2em top 55%, 0 0;
    /* background-size: .65em auto, 100%; */
  }

  .board-instructions-labels {
    top: 85%;
    left: 27%;
    text-align: left;
  }

  .instructions-container {
    width: 100%;
    /* background-color: darkmagenta; */
  }

  .instruction-par {
    margin-top: 20px;
  }

  .instructions {
    padding-top: 40px;
    margin-left: 6%;
    /* background-color: red; */
  }

  .btn-instructions {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 90%;
  }

  .btn-skip-instructions {
    margin-top: 10px;
    margin-bottom: 50px;
    width: 90%;
  }

  .info-form-container {
    width: 100%;
  }

  .info-form {
    width: 80%;
    padding: auto;
    /*background-color: orange;*/
  }

  .img-logo-info-form {
    height: 42%;
    width: 42%;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .instructions-h1 {
    font-size: 2em;
    font-weight: normal;
    line-height: 1em;
    text-align: left;
  }

  .instructions-h2 {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1.3em;
    text-align: left;
  }

  .info-form-h1 {
    font-size: 1.8em;
    font-weight: normal;
    line-height: 1em;
    margin-top: 30%;
  }

  .info-form-h2 {
    font-size: 1.3em;
    font-weight: normal;
    line-height: 1.3em;
  }

  .info-form-label {
    font-family: Roboto Condensed;
    color: #d2d2d2;
    font-size: 1.1em;
  }

  .info-form-text-field {
    font-size: 1.5em;
  }

  .btn-take-challange-now-thanks {
    font-size: 0.8em;
    font-weight: bold;
    line-height: 34px;
    height: 40px;
    width: 300px;
    margin-top: 15px;
  }

  .btn-take-challange-now {
    font-size: 0.8em;
    font-weight: bold;
    line-height: 34px;
    height: 40px;
    width: 320px;
    margin-top: 15px;
  }

  .btn-watch-random-selection {
    margin-top: 10px;
  }

  .btn-take-challange-again {
    margin: 10px 15%;
    width: 70%;
  }

  .btnChallengeFriends {
    margin: 10px 15%;
    width: 70%;
  }

  .btnViewLeaderBoard {
    margin: 10px 15%;
    width: 70%;
  }

  .leader-board-table-body-cell {
    display: table-cell;
    color: #1c1c1c;
    padding: 10px;
    text-align: center;
    border: 1px solid #979797;
    border-right: none;
  }

  .leader-board-table-header-cell {
    display: table-cell !important;
    padding: 10px !important;
    text-align: center;
    border: 1px solid #979797 !important;
    border-right: none;
  }

  .lbt-wide-cell {
    display: none !important;
    color: orange;
  }

  .leader-board-top-margin {
    margin-top: 2%;
    margin-bottom: 40%;
  }

  .leader-board-h1 {
    font-size: 2em;
    font-weight: normal;
    line-height: 1em;
    width: 90%;
  }

  .leader-board-title-top-margin {
    margin-top: 2em;
  }

  .leader-board-h2 {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1em;
  }

  .game {
    width: 100%;
  }

  .game-board {
    width: 100%;
  }

  .square {
    height: 300px;
    width: 100%;
  }

  .tap-out-footer {
    width: 100%;
  }

  .auto-tap-warning {
    width: 100%;
    font-size: 1.1em;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
  }

  .game-info {
    width: 100%;
  }

  .btnTapOut {
    width: 96%;
    margin-left: 2%;
  }

  .game-header {
    width: 100%;
    height: 45px;
  }

  .btn-header {
    background-size: 13%;
    height: 45px;
  }

  .card-between-intervals {
    width: 100%;
  }

  .card-between-intervals-border {
    margin-bottom: -20px;
  }

  /* .card-between-intervals-no-border {
    margin-bottom: -20px;
  } */

  .cbi-border-height {
    height: 670px;
  }

  .cbi-border-game-over-height {
    height: 820px;
  }

  .card-between-intervals-h1 {
    margin-top: -100px;
  }

  .card-game-over-h1 {
    font-size: 3em;
    line-height: 2em;
    margin-top: 90px;
  }
}

@media screen and (width < 800px) {
  .leader-board-top-margin {
    margin-top: 3%;
    margin-bottom: 50%;
  }

  .header-nav-motto {
    font-family: Roboto Condensed;
    color: #d2d2d2;
    font-size: 1.3em;
    font-weight: bold;
    line-height: 3.2em;
    /* background-color: salmon; */
    text-align: center;
    width: 100%;
  }
}

@media screen and (width > 800px) {
  .leader-board-top-margin {
    margin-top: 4%;
    margin-bottom: 20%;
  }

  .leader-board-container {
    background-size: contain !important;
  }

  .header-nav-motto {
    font-family: Roboto Condensed;
    color: #d2d2d2;
    font-size: 1.8em;
    font-weight: normal;
    line-height: 2.3em;
    /* background-color: salmon; */
    text-align: center;
    width: 100%;
  }
}

/* Anything else > 590px */
@media screen and (width > 590px) {
  .card-between-intervals {
    width: 100%;
  }

  .master-class-link-container {
    width: 590px;
  }

  .leader-board-container {
    background-size: contain;
    background-position: 50% 8%; /* Center the image */
  }

  body {
    margin: 0;
    background: #000;
  }

  .leader-board-h3 {
    margin-top: 1.8em;
  }

  .btn-learn-more {
    width: 200px;
    margin-top: 9px;
    margin-left: auto;
    margin-right: 10px;
  }

  .header-nav-game {
    display: flex;
    top: 0;
  }

  .practice-footer-correct-answer-txt {
    line-height: 2.2em;
    height: 50px;
    margin-left: 64%;
  }

  .btn-take-challange-now {
    font-size: 1.7em;
    font-weight: bold;
    line-height: 68px;
    /* height: 80px; */
    width: 580px;
    margin-top: 3%;
  }

  .btn-take-challange-now-thanks {
    font-size: 1.7em;
    font-weight: bold;
    line-height: 68px;
    /* height: 80px; */
    width: 500px;
    margin-top: 3%;
  }

  .btn-watch-random-selection {
    margin-top: 2em;
  }

  .btn-take-challange-again {
    margin: 10px 25%;
    width: 50%;
  }

  .btnChallengeFriends {
    margin: 10px 25%;
    width: 50%;
  }

  .btnViewLeaderBoard {
    margin: 10px 25%;
    width: 50%;
  }

  .info-form-container {
    width: 590px;
    border: 1px solid #333;
  }

  .board-instructions-color {
    top: 45%;
    left: 22%;
    text-align: left;
    background-image: url("/inst-arrow-colors.png");
    background-repeat: no-repeat, repeat;
    background-position: left 2.5em top 55%, 0 0;
    /*background-size: .65em auto, 100%;  */
  }

  .board-instructions-labels {
    top: 85%;
    left: 35%;
    text-align: left;
  }

  .instructions-container {
    width: 590px;
    border: 1px solid #333;
    /* background-color: darkmagenta; */
  }

  .instruction-par {
    margin-top: 20px;
  }

  .instructions {
    padding-top: 40px;
    margin-left: 10%;
    /* background-color: red; */
  }

  .img-logo-info-form {
    height: 42%;
    width: 42%;
    margin-top: 50px;
    margin-bottom: 35px;
  }

  .btn-instructions {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 88%;
  }

  .btn-skip-instructions {
    margin-top: 10px;
    margin-bottom: 50px;
    width: 88%;
  }

  .instructions-h1 {
    font-size: 3em;
    font-weight: normal;
    line-height: 1em;
    margin-top: 50px;
    /* background-color: green; */
  }

  .instructions-h2 {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1.5em;
  }

  .info-form-h1 {
    font-size: 3em;
    font-weight: normal;
    line-height: 1em;
    margin-top: 20%;
  }

  .info-form-h2 {
    font-size: 2em;
    font-weight: normal;
    line-height: 1.5em;
  }

  .info-form {
    padding-top: 20px;
    width: 85%;
    padding: auto;
  }

  .info-form-label {
    font-size: 1.5em;
  }

  .info-form-text-field {
    font-size: 1.5em;
  }

  .leader-board-table-body-cell {
    display: table-cell;
    color: #1c1c1c;
    padding: 10px;
    text-align: center;
    border: 1px solid #979797;
    border-right: none;
  }

  .leader-board-table-header-cell {
    display: table-cell !important;
    padding: 10px !important;
    text-align: center;
    border: 1px solid #979797 !important;
    border-right: none;
  }

  .leader-board-h1 {
    font-size: 3.9em;
    font-weight: normal;
    line-height: 1em;
  }

  .leader-board-title-top-margin {
    margin-top: 1.5em;
  }

  .leader-board-h2 {
    font-size: 2em;
    font-weight: normal;
    line-height: 1em;
    width: 90%;
  }

  .game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5%;
  }

  .card-between-intervals-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 590px;
    border: 1px solid #333;
    margin-bottom: 20px;
  }

  .card-between-intervals-no-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    margin-bottom: 20px;
  }

  .cbi-border-height {
    height: 670px;
  }

  .cbi-border-game-over-height {
    height: 820px;
  }

  .card-between-intervals-h1 {
    margin-top: 20px;
  }

  .game {
    width: 590px;
    border: 1px solid #333;
    padding-bottom: 10px;
  }

  .game-board {
    width: 100%;
  }

  .square {
    height: 344px;
    width: 100%;
  }

  .tap-out-footer {
    width: 100%;
  }

  .auto-tap-warning {
    width: 100%;
    font-size: 1.4em;
    font-weight: normal;
    line-height: 50px;
    text-align: center;
  }

  .btnTapOut {
    width: 96%;
    margin-left: 2%;
  }

  .game-header {
    width: 590px;
    height: 50px;
  }

  .btn-header {
    background-size: 10%;
    height: 50px;
  }

  .card-game-over-h1 {
    font-size: 4em;
    line-height: 2em;
  }
}

/* ----------- GLOBAL --------------*/

.leader-board-h2-center {
  margin-left: auto;
  margin-right: auto;
}

.card-game-over-red-h2 {
  color: #d7203b;
}

.btn-background-color-go-red {
  background-color: #ac3547 !important;
}

.card-game-over-line-height {
  line-height: 1.3em;
}

.margin-top-tight {
  margin-top: -8px !important;
}

.header-nav-logo {
  background-color: #000;
  background-image: url("/mtc-rewire-hor-logo.png");
  background-position: left; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 90%;
  margin-left: 20px;
  width: 150px;
}

.header-nav-text {
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
}

.footer-nav-item {
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
  background-color: seagreen;
}

.footer-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 57px;
  font-family: Roboto Condensed;
  color: #d2d2d2;
  font-size: 1.3em;
  line-height: 1.3em;
  margin-top: 70px;
  margin-bottom: 20px;
}

.footer-nav-container a,
.footer-nav-container a:visited,
.footer-nav-container a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  max-width: 100%;
}

.footer-nav-container a:hover {
  outline: 0;
  text-decoration: underline;
}

.header-nav-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 57px;
  background-color: black;
  color: #fff;
  top: 0;
}

.btn-learn-more {
  background: #252525;
  border: 0px;
  color: #fff;
  float: right;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.btn-learn-more:focus {
  outline: none;
}

.instructions {
  padding-top: 40px;
  /* background-color: red; */
}

.instructions-blue {
  font-weight: bold;
  color: blue;
}

.instructions-green {
  font-weight: bold;
  color: green;
}

.instructions-red {
  font-weight: bold;
  color: red;
}

.instruction-par {
  font-family: Roboto Condensed;
  color: #d2d2d2;
  font-size: 1.3em;
  font-weight: normal;
  line-height: 1.3em;
  text-align: left;
  margin-right: 20px;
}

.instructions-h1 {
  font-family: Roboto Condensed;
  color: #d2d2d2;
  padding: 0;
}

.instructions-h2 {
  font-family: Roboto Condensed;
  color: #d2d2d2;
  padding: 0;
}

.btn-instructions {
  background: #ac3547;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
  -webkit-appearance: none;
}

.btn-skip-instructions {
  background: #4a4a4a;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
  -webkit-appearance: none;
}

.info-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-form-h1 {
  font-family: Roboto Condensed;
  color: #d2d2d2;
  padding: 0;
}

.info-form-h2 {
  font-family: Roboto Condensed;
  color: #d2d2d2;
  padding: 0;
}

.info-form-label {
  font-family: Roboto Condensed;
  color: #d2d2d2;
}

.info-form-text-field {
  font-family: Roboto Condensed;
  color: #f30303;
  width: 90%;
  -webkit-appearance: none;
}

input[type="text"] {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 0.2em;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #d8d8d8;
}

select {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 0.2em;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #d8d8d8;
  background-image: url("/Back_Arrow_Icon.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.info-form-select::-ms-expand {
  display: none;
}
.info-form-select:hover {
  border-color: #888;
}
.info-form-select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.info-form-select option {
  font-weight: normal;
}

.master-class-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leader-board-container-material-ui {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000 !important;
}

.leader-board-container {
  background-color: #000;
  background-image: url("/iStock-585063802_1030_2.png");

  background-repeat: no-repeat; /*Do not repeat the image */
  border: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leader-board-offer-container {
  border: 0px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leader-board-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.MuiTablePagination-caption {
  font-size: 1em !important;
  font-weight: bold !important;
  line-height: 10em;
  display: none !important;
}

.MuiTableCell-footer {
  font-size: 0.12em !important;
  font-weight: bold !important;
  line-height: 10em;
}

.leader-board-table {
  font-family: Roboto Condensed;
  font-weight: normal;
  display: table;
  width: 80% !important;
  margin-top: 20px;
  margin-bottom: 40px;
  background-color: #ccc;
}

.leader-board-table-header {
  display: table-header-group;
  /* background-color: #1C1C1C !important; */
  background-color: #e20404 !important;
  color: white;
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1em;
}

.leader-board-table-footer-spacer {
  display: table-caption;
  background-color: #1c1c1c;
  border: 1px solid #979797;
  caption-side: bottom;
  padding: 20px;
}

.leader-board-table-footer {
  display: table-caption;
  caption-side: bottom;
  padding: 20px;
  font-size: 1.5em;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.leader-board-table-header-cell:last-child {
  border-right: 1px solid #979797 !important;
}

.leader-board-table-body {
  display: table-row-group;
  font-size: 1.5em !important;
  font-weight: normal !important;
  line-height: 1em !important;
}

.leader-board-table-row {
  display: table-row !important;
}

.leader-board-link {
  text-decoration: none;
  color: #1c1c1c;
}
.leader-board-link:active {
  color: #1c1c1c;
}

.leader-board-table-row:nth-child(even) {
  background: #ccc !important;
}
.leader-board-table-row:nth-child(odd) {
  background: #fff !important;
}

.leader-board-table-body-cell:last-child {
  border-right: 1px solid #796666 !important;
}

.leader-board-h1 {
  font-family: Roboto Condensed;
  color: #fff;
  padding: 0;
  text-align: center;
}

.leader-board-h2 {
  font-family: Roboto Condensed;
  color: #fff;
  padding: 0;
  text-align: center;
}

.leader-board-h3 {
  font-family: Roboto Condensed;
  color: #fff;
  padding: 0;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 1.7em;
  font-weight: normal;
  line-height: 1.1em;
}

.leader-board-h4 {
  font-family: Roboto Condensed;
  color: #fff;
  font-weight: bold;
  padding: 0;
  margin-top: 1em;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1em;
}

.square {
  font-family: Roboto Condensed;
  font-size: 4em;
  font-weight: normal;
  line-height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
}

.score-wrong-answer-animation {
  /* animation: scoreShake 0.82s cubic-bezier(.36,.07,.19,.97) both; */
  animation: scoreShake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes scoreShake {
  10%,
  90% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(6px, 0, 0);
  }
}

.square-wrong-answer-animation {
  background-color: #000;
  animation: redflash 200ms;
}

@keyframes redflash {
  0% {
    background-color: black;
  }
  20% {
    background-color: rgb(99, 2, 2);
  }
  100% {
    background-color: black;
  }
}

ol,
ul {
  padding-left: 30px;
}

.board-instructions {
  font-family: Roboto Condensed;
  font-size: 1.3em;
  line-height: 1.3em;
  position: absolute;
  color: #d2d2d2;
  width: 90%;
  padding: 2% 5% 0 5%;
  top: 0;
  left: 0;
  text-align: center;
}

.board-instructions-color {
  font-family: Roboto Condensed;
  font-size: 1.8em;
  line-height: 1.3em;
  position: absolute;
  color: #d2d2d2;
  width: 4em;
  /* background-color: rebeccapurple;
  width: 90%; */
  /* padding: 2% 5% 0 5%; 
  background-image: url('/inst-arrow-colors.png');
  background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;*/
}

.board-instructions-labels {
  font-family: Roboto Condensed;
  font-size: 1.8em;
  line-height: 1.3em;
  position: absolute;
  color: #d2d2d2;
  /* width: 90%; */
  /* padding: 2% 5% 0 5%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
  /* background-color: rebeccapurple; */
}

.board-instructions-labels-arrows {
  margin: 0.5em;
}

.board {
  background: #000;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.btn-take-challange-now-thanks {
  background: #ac3547;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  padding: 0px;
  text-align: center;
  -webkit-appearance: none;
}

.btn-take-challange-now {
  background: #ac3547;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  padding: 0px;
  text-align: center;
  -webkit-appearance: none;
}

.btn-take-challange-now:focus {
  outline: none;
}
.btn-take-challange-now-thanks:focus {
  outline: none;
}

.btn-watch-random-selection {
  background: #4a4a4a;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
  width: 320px;
  -webkit-appearance: none;
}

.btn-watch-random-selection:focus {
  outline: none;
}

.info-form-submit {
  width: 90%;
  margin-left: 5%;
}

.btn-ldb-view-all-results {
  background: #4a4a4a;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
  width: 200px;
  margin-bottom: 30px;
}

.btn-ldb-view-all-results:focus {
  outline: none;
}

.btn-learn-science {
  background: #2f2f2f;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  font-size: 1em;
  line-height: em;
  font-weight: bold;
  text-decoration: none;
  height: 40px;
  text-align: center;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.btn-learn-science:focus {
  outline: none;
}

.btn-master-class {
  background: #700200;
  border: 0px;
  color: #fff;
  float: center;
  border-radius: 5px;
  font-size: 1.2em;
  line-height: 1em;
  font-weight: bold;
  text-decoration: none;
  height: 40px;
  text-align: center;
  width: 300px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btn-master-class:focus {
  outline: none;
}

.btn-take-challange-again {
  background: #ee5a21;
  border: 0px;
  color: #fff;
  float: left;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.btn-take-challange-again:focus {
  outline: none;
}

.btnTapOut:focus {
  outline: none;
}

.btnChallengeFriends {
  background: #ac3547;
  border: 0px;
  color: #fff;
  float: left;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.btnViewLeaderBoard {
  background: #55606e;
  border: 0px;
  color: #fff;
  float: left;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.btnChallengeFriends:focus {
  outline: none;
}
.btnViewLeaderBoard:focus {
  outline: none;
}

.btn-share-fb {
  background-color: black;
  background-image: url("/FB.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  border: 0px;
  width: 73px;
  height: 73px;
  margin: 15px 4px;
}

.btn-share-fb:focus {
  outline: none;
}

.btn-share-twitter {
  background-color: black;
  background-image: url("/TWITTER.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  border: 0px;
  width: 73px;
  height: 73px;
  margin: 15px 4px;
}

.btn-share-twitter:focus {
  outline: none;
}

.btn-share-linkdin {
  background-color: black;
  background-image: url("/LINKDIN.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  border: 0px;
  width: 73px;
  height: 73px;
  margin: 15px 4px;
}

.btn-share-linkdin:focus {
  outline: none;
}

.btn-share-email {
  background-color: black;
  background-image: url("/EMAIL.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  border: 0px;
  width: 73px;
  height: 73px;
  margin: 15px 4px;
}

.btn-share-email:focus {
  outline: none;
}

.btn-header {
  background-color: #151515;
  background-image: url("/mtc-logo-trnsp.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  border: 0px;
  border-bottom: 2px solid #000;
  width: 100%;
}

.btn-header:focus {
  outline: none;
}

.card-game-over-h1 {
  font-family: Roboto Condensed;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
}

.card-game-over-rank-desc {
  font-family: Roboto;
  font-size: 1.3em;
  text-align: center;
  color: #fff;
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.card-game-over-score {
  font-family: Roboto;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  color: rgba(215, 32, 59, 0.99);
  letter-spacing: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.text-style-1 {
  font-size: 0.8em;
}

.card-count-down-txt {
  font-family: Roboto Condensed;
  font-weight: bold;
  font-size: 6em;
  text-align: center;
  color: #fff;
  margin: 0.5em;
}

.card-game-over-txt-font-size {
  font-size: 15px;
  padding-left: 60px;
}

.card-center-txt {
  /* You’ve just earned 1: */
  font-family: Roboto;
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  letter-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  /* padding-bottom: 1em;*/
}

.card-center-txt-top {
  /* You’ve just earned 1: */
  font-family: Roboto Condensed;
  font-size: 2.3em;
  text-align: center;
  color: #fff;
  letter-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -15px;
  /* padding-bottom: 1em;*/
}

.card-left-just-txt-top {
  /* You’ve just earned 1: */
  font-family: Roboto;
  font-size: 1.5em;
  text-align: left;
  color: #fff;
  letter-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -15px;
  /* padding-bottom: 1em;*/
}

.card-left-just-txt {
  /* Total Score: 1,531: */
  font-family: Roboto;
  font-size: 1.4em;
  text-align: left;
  color: #9b9b9b;
  line-height: 1.7em;
  letter-spacing: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

.card-game-over-data {
  /* Total Score: 1,531: */
  color: #fff;
  font-weight: bold;
}

.card-stats-cars-txt-font-size {
  font-size: 2em;
  padding-left: 10%;
}

.card-between-intervals-h1 {
  font-family: Roboto Condensed;
  font-size: 3.7em;
  font-weight: bold;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  /* margin-top: -40px; */
  margin-bottom: 20px;
}

.card-between-intervals-h2 {
  font-family: Roboto;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 5px;
}

.card-between-intervals-h3 {
  font-family: Roboto;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-between-intervals-txt-top {
  /* You’ve just earned 1: */
  font-family: Roboto;
  font-size: 3em;
  color: #ffffff;
  letter-spacing: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-between-intervals-txt {
  /* Total Score: 1,531: */
  font-family: Roboto;
  color: #9b9b9b;
  letter-spacing: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-between-intervals-data {
  /* Total Score: 1,531: */
  font-family: Roboto;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.card-between-intervals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  font-family: Roboto;
  /* Total Score: 1,531: */
  height: 670px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
}

.card-game-over-top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  font-family: Roboto;
  /* Total Score: 1,531: */
  /* height: 670px; */
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100%;
  height: auto;
}

.square:focus {
  outline: none;
}

.btnLeftRight {
  font-family: Roboto Condensed;
  font-size: 1.5em;
  font-weight: normal;
  background: #202020;
  border: 0px;
  line-height: 34px;
  height: 80px;
  padding: 0;
  text-align: center;
  margin: 0.25%;
  width: 49.5%;
}

.btnLeftRight:focus {
  outline: none;
}

.btnTapOut {
  background: #ac3547;
  border: 0px;
  color: #fff;
  font-family: Roboto Condensed;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.btnTapOut:focus {
  outline: none;
}

.auto-tap-warning {
  font-family: Roboto Condensed;
  border: 0px;
  height: 50px;

  font-weight: bold;
  line-height: 50px;
  text-align: center;
}

.auto-tap-warning-red {
  color: red;
}

.auto-tap-warning-white {
  color: white;
}

.practice-round-footer-instructions {
  font-family: Roboto Condensed;
  font-size: 1.2em;
  font-weight: bold;
  color: #bfbfbf;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.practice-footer-correct-answer-txt {
  font-family: Roboto Condensed;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: column;
}

.game-info {
  display: flex;
  flex-direction: row;
}

.game-info-top-padder {
  background: #202020;
  border-top: 1px solid #333;
  height: 10px;
  width: 100%;
}

.game-info-bottom-padder {
  background: #202020;
  border-bottom: 1px solid hsl(0, 97%, 49%);
  height: 10px;
  width: 100%;
}

.gic-center {
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

.game-info-col {
  font-family: Roboto Condensed;
  background: #202020;
  color: #fff;
  /*border: 1px solid #333333;*/

  font-size: 0.8em;
  height: 40px;

  width: 100vw;
  display: flex;
  flex-direction: column;
}

.game-info-head {
  color: #b2b2b2;
  font-size: 1em;
  line-height: 11px;
  margin-top: 0px;
  margin-bottom: 2px;
  padding: 0;
  text-align: center;
  height: 100vw;
}

.game-info-data {
  font-size: 2em;
  line-height: 18px;

  margin-top: 2px;
  margin-bottom: 16px;
  padding: 0;
  text-align: center;
}

.cssRED {
  color: #f43b2b;
}

.faded-cssRED {
  color: #ff6b5f;
}

.cssBLUE {
  color: #0fafee;
}

.faded-cssBLUE {
  color: #7bd8fd;
}

.cssGREEN {
  color: #41af27;
}

.faded-cssGREEN {
  color: #aaf898;
}

.cssPURPLE {
  color: #d449ef;
}

.faded-cssPURPLE {
  color: #ef9dff;
}

.cssBROWN {
  color: #c86000;
}

.faded-cssBROWN {
  color: #b47e4c;
}

.cssPINK {
  color: #f84950;
}

.faded-cssPINK {
  color: #fd9ea2;
}
